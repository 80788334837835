import * as React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import RssFeedItemsCmp from '../components/rss/RssItemsCmp';
import RssItemsCmp from '../components/rss/RssItemsCmp';
import eventBus from '../lib/EventBus';
import { Container } from 'reactstrap';
import useDataProvider from '../providers/useDataProvider';

export default function RssLatestPage(props) {
  const { getLatestItems } = useDataProvider();

  const dataRefreshTime = 1000 * 30;
  const dataCacheTime = 1000 * 60 * 60;

  const requestFeedItemsLatest = async () => {
    let data = await getLatestItems();
    return await data.result;
  };

  const { data: result, isFetching } = useQuery(
    'latestNews',
    requestFeedItemsLatest,
    {
      cacheTime: dataCacheTime,
      refetchInterval: dataRefreshTime,
      refetchOnWindowFocus: true,
      onError: (err) => console.log(err),
    },
  );

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.pageTitleChange, {
      pageTitle: 'Son Dakika Haberleri',
    });
    document.title = 'Gazete Bayii - Son Dakika Haberleri';
  }, []);

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.loadingChange, {
      loading: isFetching,
    });
  }, [isFetching]);

  return (
    <Container>
      {result ? (
        <React.Fragment>
          <RssFeedItemsCmp />
          <RssItemsCmp items={result} />
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </Container>
  );
}
