import React, { useEffect, useState } from 'react';
import './RssItemPage.css';
import eventBus from '../lib/EventBus';
import LoadingCmp from '../components/common/LoadingCmp';
import useDataProvider from '../providers/useDataProvider';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

export default function RssItemPage(props) {
  const { innerWidth: width, innerHeight: height } = window;
  const { getItem } = useDataProvider();
  const { itemId } = useParams();

  const requestFeedItemsLatest = async () => {
    let data = await getItem(itemId);
    return await data.result;
  };

  const { data: result, isFetching } = useQuery(
    ['item', itemId],
    requestFeedItemsLatest,
    {
      onError: (err) => console.log(err),
    },
  );

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.setScrolled, { minimize: true });

    return () => {
      eventBus.dispatch(eventBus.eventTypes.setScrolled, { minimize: false });
    };
  }, []);

  useEffect(() => {
    if (result && result.title) {
      eventBus.dispatch(eventBus.eventTypes.pageTitleChange, {
        pageTitle: result.title,
      });
      document.title = 'Gazete Bayii - ' + result.title;
    }
  }, [result]);

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.loadingChange, {
      loading: isFetching,
    });
  }, [isFetching]);

  if (result && Object.keys(result).length !== 0) {
    // const itemUrl = "https://cors.io/?" + result.url;
    // const itemUrl = "https://yacdn.org/proxy/" + result.url;
    const itemUrl = result.url;
    const itemTitle = result.title;
    const hasFrameOptions = result.hasFrameOptions;
    let content;
    if (hasFrameOptions)
      content = (
        <div className="full-page-link">
          <a href={itemUrl} target="_blank" rel="noopener noreferrer">
            Yeni sayfada açmak için tıklayınız.
          </a>
        </div>
      );
    else
      content = (
        <iframe
          title={itemTitle}
          src={itemUrl}
          id={'itemContent'}
          width={width}
          height={height - 65}
          frameBorder="0"
          allow="fullscreen"
          sandbox="allow-scripts allow-same-origin"
        />
      );
    return (
      <React.Fragment>
        <div>{content}</div>
      </React.Fragment>
    );
  } else {
    eventBus.dispatch(eventBus.eventTypes.pageTitleChange, {
      pageTitle: 'Yükleniyor',
    });
    return <LoadingCmp />;
  }
}
