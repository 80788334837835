import React, { useEffect } from 'react';
import RssSiteCmp from '../components/rss/RssSiteCmp';
import RssItemsCmp from '../components/rss/RssItemsCmp';
import RssFeedsCmp from '../components/rss/RssFeedsCmp';
import BreadCrumbCmp from '../components/common/BreadCrumbCmp';
import eventBus from '../lib/EventBus';
import { Container } from 'reactstrap';
import useDataProvider from '../providers/useDataProvider';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

export default function RssSitePage(props) {
  const { getSiteItems } = useDataProvider();
  const { siteId } = useParams();

  const dataRefreshTime = 1000 * 30;
  const dataCacheTime = 1000 * 60 * 60;

  const requestFeedItemsLatest = async () => {
    let data = await getSiteItems(siteId);
    return await data.result;
  };

  const { data: result, isFetching } = useQuery(
    ['site', siteId],
    requestFeedItemsLatest,
    {
      cacheTime: dataCacheTime,
      refetchInterval: dataRefreshTime,
      refetchOnWindowFocus: true,
      onError: (err) => console.log(err),
    },
  );

  useEffect(() => {
    if (result && result.site) {
      eventBus.dispatch(eventBus.eventTypes.pageTitleChange, {
        pageTitle: result.site.siteTitle,
      });
      document.title = 'Gazete Bayii - ' + result.site.siteTitle;
    }
  }, [result]);

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.loadingChange, {
      loading: isFetching,
    });
  }, [isFetching]);

  return (
    <Container>
      {result ? (
        <>
          <BreadCrumbCmp {...result.breadcrumbContainer} />
          <RssSiteCmp {...result.site} />
          <RssFeedsCmp feeds={result.feedList} />
          <hr />
          <RssItemsCmp items={result.itemList} />
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
