import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import { createBrowserHistory } from 'history';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>,
);

// ReactDOM.render(
//     <BrowserRouter history={history}>
//         <App />
//     </BrowserRouter>,
//     document.getElementById('root'));

registerServiceWorker();
// unregister();
