export default function useDataProvider() {
  const getBaseResponse = async (url) => {
    let response = await fetch(url);
    if (!response.ok) {
      console.error(
        new Error(
          `HTTP error! Status: ${response.status} StatusText: ${response.statusText}`,
        ),
      );
    }
    return await response.json();
  };

  const getHomepageItems = () => {
    const homepageApiUrl = 'api/home/homepage';
    return getBaseResponse(homepageApiUrl);
  };

  const getFeedItems = (feedId) => {
    const feedApiUrl = 'api/feed/' + feedId;
    return getBaseResponse(feedApiUrl);
  };

  const getLatestItems = () => {
    const itemLatestApiUrl = 'api/item/latest';
    return getBaseResponse(itemLatestApiUrl);
  };

  const getSiteItems = (siteId) => {
    const siteApiUrl = 'api/site/' + siteId;
    return getBaseResponse(siteApiUrl);
  };

  const getItem = (itemId) => {
    const itemApiUrl = 'api/item/' + itemId;
    return getBaseResponse(itemApiUrl);
  };

  return {
    getHomepageItems,
    getSiteItems,
    getFeedItems,
    getLatestItems,
    getItem,
  };
}
