import * as React from 'react';
import NavMenu from './NavMenu';
import eventBus from '../../lib/EventBus';
import { useEffect, useState } from 'react';

export default function Layout(props) {
  const [offset, setOffset] = useState('57px');

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child);
  });

  useEffect(() => {
    eventBus.on(eventBus.eventTypes.setScrolled, (data) => {
      if (data.minimize) {
        setOffset('42px');
      } else {
        setOffset('57px');
      }
    });

    return () => {
      eventBus.remove(eventBus.eventTypes.pageTitleChange, () => {});
    };
  }, []);

  return (
    <div style={{ paddingTop: offset }}>
      <NavMenu />
      {children}
    </div>
  );
}
