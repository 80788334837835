import React from 'react';
import { Link } from 'react-router-dom';

export default function BreadCrumbCmp(props) {
  function renderItem(item) {
    if (!item.isLast) {
      return (
        <React.Fragment>
          <Link to={item.link}>{item.title}</Link>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span>{item.title}</span>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <div className="breadcrumb">
        {props.items &&
          props.items.map((item) => (
            <span key={item.link} className="breadcrumb-item">
              {renderItem(item)}
            </span>
          ))}
      </div>
    </React.Fragment>
  );
}
