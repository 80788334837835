import * as React from 'react';
import './RssItemsCmp.css';
import RssItemCmp from './RssItemCmp';

export default function RssItemsCmp(props) {
  if (!props.items) return null;

  return (
    <div>
      {props.items.map((rssItem) => (
        <RssItemCmp key={rssItem.id} item={rssItem} />
      ))}
    </div>
  );
}
