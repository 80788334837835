import * as React from 'react';
import eventBus from '../lib/EventBus';
import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import useDataProvider from '../providers/useDataProvider';
import { Link } from 'react-router-dom';

export default function HomePage() {
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const { getHomepageItems } = useDataProvider(setLoading);

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.pageTitleChange, { pageTitle: '' });
    document.title = 'Gazete Bayii - Anasayfa';
  }, [result]);

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.loadingChange, {
      loading: isLoading,
    });
  }, [isLoading]);

  useEffect(() => {
    getHomepageItems().then((data) => setResult(data.result));
  }, []);

  return (
    <Container>
      {result ? (
        <Row>
          {result.map((site) => (
            <Col key={site.id} sm="3">
              <Link to={`/site/${site.id}`}>
                <Card sm="6">
                  <CardBody>{site.siteTitle}</CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      ) : (
        <React.Fragment />
      )}
    </Container>
  );
}
