import * as React from 'react';
import { Route, Routes } from 'react-router';
import Layout from './components/common/Layout';
import HomePage from './pages/HomePage';
import RssSitePage from './pages/RssSitePage';
import RssFeedPage from './pages/RssFeedPage';
import RssLatestPage from './pages/RssLatestPage';
import RssItemPage from './pages/RssItemPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import './custom.css';

const queryClient = new QueryClient();

export default () => (
  <QueryClientProvider client={queryClient}>
    <Layout>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/site/:siteId?" element={<RssSitePage />} />
        <Route path="/feed/:feedId?" element={<RssFeedPage />} />
        <Route path="/item/:itemId?" element={<RssItemPage />} />
        <Route path="/son-dakika" element={<RssLatestPage />} />
      </Routes>
    </Layout>
  </QueryClientProvider>
);
