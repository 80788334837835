import * as React from 'react';
import { Link } from 'react-router-dom';
import './RssFeedsCmp.css';

export default function RssFeedsCmp(props) {
  return (
    <React.Fragment>
      <div>
        {props.feeds &&
          props.feeds.map((feed) => (
            <span key={feed.id} className="siteFeed">
              <Link to={`/feed/${feed.id}`}>{feed.title}</Link>
            </span>
          ))}
      </div>
    </React.Fragment>
  );
}
