import * as React from 'react';
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import eventBus from '../../lib/EventBus';
import LoadingCmp from './LoadingCmp';
import { useEffect, useState } from 'react';

export default function NavMenu(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    eventBus.on(eventBus.eventTypes.pageTitleChange, (data) => {
      setPageTitle(data.pageTitle);
    });

    return () => {
      eventBus.remove(eventBus.eventTypes.pageTitleChange);
    };
  }, []);

  useEffect(() => {
    eventBus.on(eventBus.eventTypes.setScrolled, (data) => {
      const navbar = document.getElementsByTagName('nav')[0];
      if (data.minimize) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    });

    return () => {
      eventBus.remove(eventBus.eventTypes.setScrolled);
    };
  }, []);

  useEffect(() => {
    eventBus.on(eventBus.eventTypes.loadingChange, (data) => {
      setIsLoading(data.loading);
    });

    return () => {
      eventBus.remove(eventBus.eventTypes.loadingChange);
    };
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const navbar = document.getElementsByTagName('nav')[0];
    if (offset > 100) {
      navbar.classList.add('scrolled');
    } else if (offset !== 0) {
      navbar.classList.remove('scrolled');
    }
  }, [offset]);

  function toggle() {
    setIsOpen(!isOpen);
  }

  return (
    <header className="fixed-top">
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm border-bottom"
        light
      >
        <Container className="headerContainer">
          <NavbarBrand tag={Link} to="/">
            Gazete Bayii
          </NavbarBrand>
          <span className="d-none d-md-block pageTitle" title={pageTitle}>
            {pageTitle}
          </span>
          <NavbarToggler onClick={toggle} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={isOpen}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/son-dakika">
                  Son Dakika
                </NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Container>
      </Navbar>
      {isLoading && <LoadingCmp />}
    </header>
  );
}
