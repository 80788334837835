import * as React from 'react';
import RssFeedCmp from '../components/rss/RssFeedCmp';
import RssItemsCmp from '../components/rss/RssItemsCmp';
import BreadCrumbCmp from '../components/common/BreadCrumbCmp';
import eventBus from '../lib/EventBus';
import { useEffect } from 'react';
import { Container } from 'reactstrap';
import useDataProvider from '../providers/useDataProvider';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

export default function RssFeedPage(props) {
  const { getFeedItems } = useDataProvider();
  const { feedId } = useParams();

  const dataRefreshTime = 1000 * 30;
  const dataCacheTime = 1000 * 60 * 60;

  const requestFeedItemsLatest = async () => {
    let data = await getFeedItems(feedId);
    return await data.result;
  };

  const { data: result, isFetching } = useQuery(
    ['feed', feedId],
    requestFeedItemsLatest,
    {
      cacheTime: dataCacheTime,
      refetchInterval: dataRefreshTime,
      refetchOnWindowFocus: true,
      onError: (err) => console.log(err),
    },
  );

  useEffect(() => {
    if (result && result.feed) {
      eventBus.dispatch(eventBus.eventTypes.pageTitleChange, {
        pageTitle: result.feed.title,
      });
      document.title = 'Gazete Bayii - ' + result.feed.title;
    }
  }, [result]);

  useEffect(() => {
    eventBus.dispatch(eventBus.eventTypes.loadingChange, {
      loading: isFetching,
    });
  }, [isFetching]);

  return (
    <Container>
      {result ? (
        <>
          <BreadCrumbCmp {...result.breadcrumbContainer} />
          <RssFeedCmp />
          <RssItemsCmp items={result.itemList} />
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
