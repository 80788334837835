import * as React from 'react';
import { Link } from 'react-router-dom';
import './RssItemsCmp.css';

export default function RssItemCmp(props) {
  const rssItem = props.item;

  let newsMedia;
  if (rssItem.shouldShowImg) {
    newsMedia = <img src={rssItem.itemMediaUrl} alt={rssItem.itemTitle} />;
  }

  return (
    <React.Fragment key={rssItem.id}>
      <div>
        {
          <Link
            key={rssItem.id}
            className="news-item"
            to={`/item/${rssItem.id}`}
          >
            <span className="news-item-title">{rssItem.itemTitle}</span>
            <span className="news-item-content">
              <p>
                {newsMedia}
                {rssItem.itemDescription}
              </p>
            </span>
            <span className="timeago" title={rssItem.timesAgoDate}>
              {rssItem.timesAgo}
            </span>
            <span className="bc">
              {rssItem.sitetitle} / {rssItem.title}
            </span>
          </Link>
        }
      </div>
    </React.Fragment>
  );
}
